import { FC, createContext } from 'react';
import { IconButton, styled, Box } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ContentEntrySidePanel, {
  useContentEntrySidePanel,
} from 'components/content-entry-side-panel';
import CollectionWrapper from 'components/content-entry-side-panel/collection-wrapper';
import SidePanelContainer from 'components/search-side-panel/side-panel-container';
import useOzmoApiService from 'contexts/ozmo-api-service-context';

import { useContentTab, useCurrentCollection } from './hooks';
import Filters from './answers/filters';
import AnswersList from './answers/answers-list';
import Categories from './categories';
import CollectionsBulkActionbar from './bulk-action-bar';

const StyledContainer = styled('div')`
  display: flex;
  /* Remove margin from the <main> */
  margin: 0 -2rem -2rem;
  /* 185px is the height of the header and navbar */
  height: calc(100vh - 225px);
  width: 100vw;

  #categories {
    /* Grow to 25%, shrink as needed, no smaller than 18.75rem */
    flex: 25 1 18.75rem;
    /*  no larger than 22.875rem */
    max-width: 22.875rem;
    /* To absolutely position the open button */
    position: relative;
    background-color: var(--color-neutral-one);
    padding: 1.5rem 0 0;
    transition: flex 0.2s ease-in-out;
    box-shadow: ${({ theme }) => theme.shadows[1]};
    /* Above the answers and info panel */
    z-index: 2;
    /* Get rid of the box shadow on the bottom */
    margin-bottom: -1px;
  }

  #categories #content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  #categories.hidden {
    flex: none;
    width: ${({ theme }) => theme.spacing(7)};
    min-width: 56px;
  }

  /* baseline styles for #answers */
  #answers {
    /* Grow to 50 */
    flex: 50 1 0;
    padding: ${({ theme }) => theme.spacing(3)};
    box-shadow: ${({ theme }) => theme.shadows[1]};
    transition: flex 0.2s ease-in-out;
    overflow-y: auto;
    /* above the info panel, below the categories */
    z-index: 1;
    /* Get rid of the box shadow on the bottom */
    margin-bottom: -1px;
  }

  #expand-button {
    position: absolute;
    right: -14px;
    top: 8px;
    height: 20px;
    width: 20px;
    /* Turn the button fully circular */
    border-radius: 16px;
    color: var(--color-neutral-one);
    background-color: var(--color-primary-base);
    box-shadow: ${({ theme }) => theme.shadows[1]};
  }

  #info-panel {
    /* 100% of the window - the appbar and collection header */
    height: calc(100vh - 225px);
    /* Grow to 25%, shrink as needed, no smaller than 18.75rem */
    flex: 25 1 18.75rem;
    /*  no larger than 22.875rem */
    max-width: 22.875rem;
    overflow: auto;
    transition: flex 0.2s ease-in-out;
  }
  #info-panel.hidden {
    flex: none;
    width: 0px;
  }
`;

export const IsReferenceSelectedContext = createContext<
  (id: number, catId: number) => boolean
>(() => false);

const ContentTab: FC = () => {
  const { languageId, languageDisplayName } = useCurrentCollection();
  const {
    sidePanelSelectedClass,
    panelItemId,
    hidePanel,
    togglePanel,
    isPanelOpen,
  } = useContentEntrySidePanel();

  const {
    isLoading,
    isReferenceSelected,
    handleDeselectAllReferences,
    handleInfoClick,
    handleSelectAllReferences,
    handleSelectReference,
    allReferences,
    selectedReferences,
    showCategories,
    toggleShowCategories,
    refetchCollection,
  } = useContentTab(togglePanel);

  const api = useOzmoApiService();
  const { data: contentEntry } = api.ContentEntry.get({
    id: panelItemId ?? undefined,
  });

  return (
    <StyledContainer>
      <Box
        id="categories"
        className={showCategories ? '' : 'hidden'}
        display="flex"
        flexDirection="column"
      >
        <IconButton
          id="expand-button"
          data-testid="expand-categories-button"
          size="small"
          onClick={toggleShowCategories}
        >
          {showCategories ? (
            <KeyboardArrowLeftIcon fontSize="small" />
          ) : (
            <KeyboardArrowRightIcon fontSize="small" />
          )}
        </IconButton>
        {showCategories && !isLoading && <Categories />}
      </Box>
      <Box
        id="answers"
        data-testid="answers-container"
        className={
          showCategories
            ? `categories-panel-open ${sidePanelSelectedClass}`
            : sidePanelSelectedClass
        }
      >
        <Filters />
        <IsReferenceSelectedContext.Provider value={isReferenceSelected}>
          <AnswersList
            onInfoClick={handleInfoClick}
            onSelectReference={handleSelectReference}
          />
        </IsReferenceSelectedContext.Provider>
      </Box>
      <div id="info-panel" className={isPanelOpen ? '' : 'hidden'}>
        <CollectionWrapper>
          {panelItemId && (
            <SidePanelContainer
              onClose={hidePanel}
              contentType={contentEntry?.contentType ?? ''}
              id={panelItemId}
            >
              <ContentEntrySidePanel
                contentEntryId={panelItemId}
                languageId={languageId}
                hasActionableLanguages={false}
              />
            </SidePanelContainer>
          )}
        </CollectionWrapper>
      </div>
      <CollectionsBulkActionbar
        languageDisplayName={languageDisplayName}
        selectedReferences={selectedReferences}
        allReferences={allReferences}
        onSelectAllReferences={handleSelectAllReferences}
        onDeselectAllReferences={handleDeselectAllReferences}
        refetchCollection={refetchCollection}
      />
    </StyledContainer>
  );
};

export default ContentTab;
