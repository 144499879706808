import { FunctionComponent } from 'react';
import StaggeredRenderedList from 'components/staggered-rendered-list';
import { isTopicGroup } from 'services/utils/type-guards/collection';

import TopicGroupComponent from '../answers-list/reference/topic-group';
import ReferenceCard from '../answers-list/reference';

import CategoryHeader from './category-header';

import { AttributeIds } from '.';

type Props = {
  attributeIds: AttributeIds;
  id: number;
  name: string;
  languageId: number;
  topicGroupedReferences: (CategoryContentEntry | CategoryContentEntry[])[];
  uniqueReferences: number[];
  collectionId: number;
  collectionName: string;
  onInfoClick: (id: number) => void;
  onSelectReference: (id: number, catId: number) => void;
};

const AnswerListCategory: FunctionComponent<Props> = ({
  attributeIds,
  id,
  collectionId,
  collectionName,
  name,
  languageId,
  topicGroupedReferences,
  uniqueReferences,
  onInfoClick,
  onSelectReference,
}) => (
  <>
    <CategoryHeader
      attributeIds={attributeIds}
      collectionId={collectionId}
      collectionName={collectionName}
      count={topicGroupedReferences.length}
      name={name}
      languageId={languageId}
      id={id}
      existingReferences={uniqueReferences}
    />
    <StaggeredRenderedList
      gap={1}
      display="flex"
      flexDirection="column"
      key={id}
    >
      {topicGroupedReferences.map((r) =>
        isTopicGroup(r) ? (
          <TopicGroupComponent
            key={`tg-${r[0].topicSlug}-${id}`}
            categoryId={id}
            collectionId={collectionId}
            references={r}
            onSelectChange={onSelectReference}
            onInfoClick={onInfoClick}
          />
        ) : (
          <ReferenceCard
            onInfoClick={onInfoClick}
            onSelectChange={onSelectReference}
            collectionId={collectionId}
            categoryId={id}
            key={`${r.id}-${id}`}
            {...r}
          />
        )
      )}
    </StaggeredRenderedList>
  </>
);

export default AnswerListCategory;
