import { HTMLProps } from 'react';
import { FCWithChildren } from 'types/fc-with-children';
import { CustomTypes } from 'slate';

import { isLinkElement } from '../types';

type Props = {
  attributes: HTMLProps<HTMLParagraphElement>;
  element: CustomTypes['Element'];
};

const Element: FCWithChildren<Props> = ({ attributes, children, element }) => {
  if (isLinkElement(element)) {
    return (
      <a href={element.url} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }
  return <p {...attributes}>{children}</p>;
};

export default Element;
