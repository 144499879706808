import { Token } from 'prismjs';
import { Descendant } from 'slate';
import { LinkElement, ParagraphElement } from 'types/slate';

export type MarkOptions = 'bold' | 'italic';

export const isLinkElement = (el: Descendant): el is LinkElement => {
  return (el as LinkElement).type === 'link';
};

export const isParagraphElement = (el: Descendant): el is ParagraphElement => {
  return (el as ParagraphElement).type === 'paragraph';
};

export const isPrismToken = (t: Token | string): t is Token =>
  (t as Token).type !== undefined;
