import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import styled from 'styled-components';
import { FunctionComponent } from 'react';
import LoadingIndicator from 'components/loading-indicator';
import MultiSelect from 'components/multi-select';

const StyledDiv = styled.div`
  padding-top: 32px;
  & > div {
    width: 25%;
    margin-right: 16px;
  }
`;

type Props = {
  isLoading: boolean;
  handleChangeSource: (e: SelectChangeEvent<number>) => void;
  sourceId?: number;
  languages: { value: number; chipLabel: string; label: string }[];
  handleSelectTarget: (languageIds: number[]) => void;
  name: string;
};

const ExportModalContent: FunctionComponent<Props> = ({
  isLoading,
  handleChangeSource,
  sourceId,
  languages,
  handleSelectTarget,
  name,
}) => {
  if (isLoading) {
    return <LoadingIndicator message="Loading collection" />;
  }

  return (
    <>
      <Typography>
        {
          'Select a source and target language for translation. All of the referenced answers within '
        }
        <b>{name}</b>
        {' will be exported.'}
      </Typography>
      <StyledDiv>
        <FormControl>
          <InputLabel>{'Source language'}</InputLabel>
          <Select<number>
            onChange={handleChangeSource}
            labelId="source-language-select-label"
            id="source-language"
            label="Source language"
            data-testid="translation-export-modal:source-language-select"
            value={sourceId}
          >
            {languages.map((l) => (
              <MenuItem key={l.value} value={l.value}>
                {`${l.label}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <MultiSelect
          label="Target languages"
          options={languages}
          onSelectionChanged={handleSelectTarget}
        />
      </StyledDiv>
    </>
  );
};

export default ExportModalContent;
