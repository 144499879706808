import { FC } from 'react';
import { styled } from '@mui/material';
import BulkActionBar from 'components/bulk-action-bar';
import { PermissionRequired } from 'components/permission-required';

import { SelectedReference } from '../hooks/use-content-tab';

import { useBulkActions } from './hooks';
import AddAttributes from './actions/add-attribute/add-attribute-modal';
import PublishAnswers from './actions/publish-answers';

type Props = {
  refetchCollection: Function;
  languageDisplayName: string;
  allReferences: (
    | LocalizedCollectionReference
    | MissingLocalizedCollectionReference
  )[];
  selectedReferences: SelectedReference[];
  onSelectAllReferences: VoidFunction;
  onDeselectAllReferences: VoidFunction;
};

const StyledBulkActionBar = styled(BulkActionBar)`
  width: auto !important;
  max-width: 900px;
  margin: 0 auto;
  bottom: 1rem;
  left: 0;
  right: 0;
  /* this is so the bulk action bar is above the answer cards */
  z-index: 2;
  box-shadow: 0 10px 20px 0 rgba(26, 36, 45, 0.19),
    0 6px 6px 0 rgba(26, 36, 45, 0.26);
`;

const CollectionsBulkActionbar: FC<Props> = ({
  languageDisplayName,
  allReferences,
  selectedReferences,
  onSelectAllReferences,
  onDeselectAllReferences,
  refetchCollection,
}) => {
  const { onAddAttributes } = useBulkActions(selectedReferences);
  const allSelected = selectedReferences.length === allReferences.length;

  return (
    <StyledBulkActionBar
      selectedCount={selectedReferences.length}
      allSelected={allSelected}
      onSelectAll={
        allSelected ? onDeselectAllReferences : onSelectAllReferences
      }
      onClose={onDeselectAllReferences}
    >
      <PermissionRequired
        permission="PATCH:/v1/authoring/content_entries/*/"
        authApplicationName="API"
      >
        <AddAttributes
          answerCount={selectedReferences.length}
          activeReferences={allReferences}
          onAddAttributes={onAddAttributes}
        />
      </PermissionRequired>
      <PermissionRequired permission="publish">
        <PublishAnswers
          refetchCollection={refetchCollection}
          selectedReferences={selectedReferences}
          languageDisplayName={languageDisplayName}
        />
      </PermissionRequired>
    </StyledBulkActionBar>
  );
};

export default CollectionsBulkActionbar;
