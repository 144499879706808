import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { extractQueryAttributes } from 'components/attribute-selector';
import { useContentEntrySearch } from 'scenes/content-filter-table-sidebar/hooks';
import { useAppToast } from 'contexts/app-toast-context';
import { convertAttributeMapToSearchParams } from 'scenes/content-search/hooks';
import ozmoApi from 'services/ozmo-api';
import { Typography } from '@mui/material';

type ExportReadyAnswer = {
  id: number;
  slug: string;
  title: string;
  space: string;
  contentType: string;
  updatedAt: string;
  attributes: string[];
  lceStatuses: {
    languageShortCode: string;
    status: LocalizedContentEntryStatus;
  }[];
};

type SearchResultLocalizationStatus = {
  languageShortCode: string;
  status: LocalizedContentEntryStatus;
};

// Requests and attaches LCE statuses to each search result
const withLceStatuses = async (searchResults: ContentSearchResult[]) => {
  const CHUNK_SIZE = 25;
  const data: (ContentSearchResult & {
    lceStatuses: SearchResultLocalizationStatus[];
  })[] = [];

  const processChunk = async (resultsChunk: ContentSearchResult[]) => {
    const promises = resultsChunk.map(({ id }) =>
      ozmoApi.LocalizedContentEntry.getAllAsync({
        contentEntryId: id,
      }).then((lceStatuses) =>
        lceStatuses.map(({ languageShortCode, status }) => ({
          languageShortCode,
          status,
        }))
      )
    );

    const dataChunk = await Promise.all(promises);
    for (let i = 0; i < resultsChunk.length; i++) {
      data.push({
        ...resultsChunk[i],
        lceStatuses: dataChunk[i],
      });
    }
  };

  // Split searchResults into chunks and process sequentially so we don't hit the concurrent connections limit
  for (let i = 0; i < searchResults.length; i += CHUNK_SIZE) {
    const chunk = searchResults.slice(i, i + CHUNK_SIZE);
    await processChunk(chunk);
  }

  return data;
};

export const removeEmptyStatusColumns = (values: string[][]) => {
  if (values.length <= 1) return values;

  const columnsToKeep = values[0].map(
    (columnHeader, columnIndex) =>
      !(
        columnHeader.endsWith(' Status') &&
        values.slice(1).every((row) => row[columnIndex] === '')
      )
  );

  return values.map((row) => row.filter((_, index) => columnsToKeep[index]));
};

export const compileCsvContent = async (
  data: (ContentSearchResult & {
    lceStatuses: SearchResultLocalizationStatus[];
  })[]
) => {
  const languages = await ozmoApi.Language.getAllAsync();

  const answers: ExportReadyAnswer[] = data.map(
    ({
      id,
      topic,
      title,
      space,
      contentType,
      updatedAt,
      devices,
      manufacturers,
      operatingSystems,
      operatingSystemReleases,
      operatingSystemVersions,
      lceStatuses,
    }) => ({
      id,
      slug: topic,
      title,
      space,
      contentType,
      updatedAt,
      attributes: [
        ...devices.map(({ name }) => name),
        ...manufacturers.map(({ name }) => name),
        ...operatingSystems.map(({ name }) => name),
        ...operatingSystemReleases.map(({ name }) => name),
        ...operatingSystemVersions.map(({ name }) => name),
      ],
      lceStatuses,
    })
  );

  const header = [
    'ID',
    'Topic_Slug',
    'English title',
    'Space',
    'Content Type',
    'Last Updated Date',
    'Attributes',
    ...languages.map(({ shortCode }) => `${shortCode} Status`),
  ];

  const values = answers.map(
    ({
      id,
      slug,
      title,
      space,
      contentType,
      updatedAt,
      attributes,
      lceStatuses,
    }) => {
      return [
        `${id}`,
        slug,
        `"${title}"`, // Just in case the title has commas
        space,
        contentType,
        updatedAt,
        `"${attributes.join(',')}"`,
        ...languages.map(
          ({ shortCode }) =>
            lceStatuses.find(
              ({ languageShortCode }) => languageShortCode === shortCode
            )?.status || ''
        ),
      ];
    }
  );

  return removeEmptyStatusColumns([header, ...values]);
};

export const useSearchResultsExport = () => {
  const [isExporting, setIsExporting] = useState(false);
  const [triggerExport, setTriggerExport] = useState(false);

  const location = useLocation();

  const { handleSearch, isSearching, searchResults } = useContentEntrySearch();
  const dispatchToast = useAppToast();

  const handleError = useCallback(
    (error: any) => {
      dispatchToast({
        level: 'error',
        message: (
          <Typography>
            {'Sorry! Something went wrong with the export. Please try again.'}
          </Typography>
        ),
      });
      console.error(error);
      setIsExporting(false);
    },
    [dispatchToast]
  );

  const downloadFile = (data: string[][]) => {
    // Make the CSV string
    const csv = data.map((row) => row.join(',')).join('\n');
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.download = 'Search results.csv';
    link.href = url;
    link.click();

    URL.revokeObjectURL(url);
  };

  const exportSearchResults = async () => {
    try {
      setIsExporting(true);

      const attributes = extractQueryAttributes(location);
      const searchFilters = convertAttributeMapToSearchParams(attributes);

      await handleSearch(searchFilters, 1000);
      // This will trigger the useEffect below to process and download the CSV file
      setTriggerExport(true);
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        // This ensures that the script runs only when the export button is clicked.
        if (!isSearching && triggerExport) {
          setTriggerExport(false);

          const searchResultsWithLceStatuses = await withLceStatuses(
            searchResults
          );
          const data = await compileCsvContent(searchResultsWithLceStatuses);
          downloadFile(data);

          setIsExporting(false);
        }
      } catch (e) {
        handleError(e);
      }
    })();
  }, [searchResults, isSearching, handleError, triggerExport]);

  return {
    exportSearchResults,
    isExporting,
  };
};
