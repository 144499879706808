import { useCallback } from 'react';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import {
  getSuccessCount,
  recursiveJobRequest,
} from 'services/ozmo-api/utils/recursive-job-request';
import { assembleBulkOperation } from 'services/utils/assemble-bulk-operation';
import { useAppToast } from 'contexts/app-toast-context';
import getPlural from 'services/utils/get-plural';

export const useCategoryHeader = (categoryId: number, collectionId: number) => {
  const api = useOzmoApiService();
  const dispatchToast = useAppToast();

  const handleRemoveReferenceFromCategory = useCallback(
    (id: number) =>
      api.Collection.deleteReferenceFromCategoryAsync(
        collectionId,
        categoryId,
        id
      ),
    [api.Collection, categoryId, collectionId]
  );

  const handleAddReferenceToCategory = useCallback(
    (id: number) =>
      api.Collection.addReferenceToCategoryAsync(collectionId, categoryId, id),
    [api.Collection, categoryId, collectionId]
  );

  const handleAddReferencesToCategory = useCallback(
    async (contentEntryIds: number[]) => {
      const operations = [
        assembleBulkOperation(
          categoryId,
          'add',
          'content_entry_ids',
          contentEntryIds
        ),
      ];
      const { job } = await api.Collection.bulkAsync(collectionId, operations);
      const response = await api.Collection.refetchedOperationAsync(
        () => recursiveJobRequest<BulkOperationJobResponse>(job),
        collectionId
      );
      const successCount = getSuccessCount(response);

      if (successCount === operations.length) {
        dispatchToast({
          level: 'success',
          message: `${contentEntryIds.length} ${getPlural(
            contentEntryIds.length,
            'answer has',
            'answers have'
          )} been successfully added to your category.`,
        });
        return true;
      }
      dispatchToast({
        level: 'error',
        message: 'We were unable to add the answers to the collection',
      });
      console.error(response);
      return false;
    },
    [api.Collection, categoryId, collectionId, dispatchToast]
  );

  return {
    handleAddReferenceToCategory,
    handleAddReferencesToCategory,
    handleRemoveReferenceFromCategory,
  };
};
