import { FC, useCallback, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid, Tabs, Tab, Button } from '@mui/material';
import { Add, Download } from '@mui/icons-material';
import { TabLabelProps } from 'scenes/tabbed-content';
import AddContent from 'scenes/add-content';
import { ContentTypes, HomeTabs } from 'types/enums';
import { useFlag } from 'services/flags';
import LoadingButton from 'components/loading-button';
import { useSearchResultsStatus } from 'contexts/search-results-status-context';

import { useSearchResultsExport } from './hooks';

type Props = Partial<TabLabelProps>;

const HomeTabbedHeader: FC<Props> = ({
  tabLabels,
  currentTab,
  setCurrentTab,
}) => {
  const useCollections2 = useFlag('useCollections2', false);

  const handleChange = useCallback(
    (_e: SyntheticEvent, newTab: number) =>
      setCurrentTab && setCurrentTab(newTab),
    [setCurrentTab]
  );

  // We check here if the context is available because this component is used in a few places
  const {
    searchResultsStatus: { searchResults, isSearching, hasError },
  } = useSearchResultsStatus() ?? { searchResultsStatus: {} };

  const { exportSearchResults, isExporting } = useSearchResultsExport();

  return (
    <Grid container marginBottom={1.5}>
      <Grid item xs={12}>
        <Typography variant="h3">{'Welcome to Ozmo Studio'}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent={useCollections2 ? 'space-between' : 'flex-end'}
      >
        {useCollections2 &&
          tabLabels &&
          currentTab !== undefined &&
          setCurrentTab && (
            <Tabs value={currentTab} onChange={handleChange}>
              <Tab label="Collections" component={Link} to="/collections" />
              <Tab label="Answers" component={Link} to="/answers" />
            </Tabs>
          )}
        {(currentTab === HomeTabs.COLLECTIONS || !useCollections2) && (
          <AddContent
            sourceName="home"
            spaceId={1}
            contentTypeAllowlist={[ContentTypes.COLLECTION]}
          >
            <Button variant="contained" startIcon={<Add />}>
              {'Create collection'}
            </Button>
          </AddContent>
        )}
        {currentTab === HomeTabs.ANSWERS && (
          <LoadingButton
            startIcon={<Download />}
            variant="contained"
            isLoading={isExporting}
            disabled={
              isExporting || !searchResults?.length || isSearching || hasError
            }
            onClick={exportSearchResults}
          >
            {isExporting ? 'Exporting...' : 'Export as CSV'}
          </LoadingButton>
        )}
      </Grid>
    </Grid>
  );
};

export default HomeTabbedHeader;
