import OzmoApiBase from 'services/ozmo-api/ozmo-api-base';
import keysToSnake from 'services/utils/convert-object-keys-camel-to-snake';
import LocalizedContentEntry from 'services/ozmo-api/localized-content-entry';
import { SelectedAttributes } from 'components/attribute-selector';

import {
  createData,
  UseQueryOptionsWithPrefetch,
  UrlParamOptions,
} from '../use-query-cache';
import { ozmoApiRequest } from '../ozmo-api';

interface ResourcePathVariables {
  id?: number | '/';
}
class ContentEntry extends OzmoApiBase<
  ContentEntryModel,
  ContentEntryUpdateModel,
  ContentEntryCreateModel,
  ResourcePathVariables
>() {
  protected static resourcePath = 'authoring/content_entries/:id';
  static embedOptions = [
    'localized_content_entries',
    'deleted_localized_content_entries',
    'references',
    'devices',
    'device_types',
    'manufacturers',
    'operating_systems',
    'operating_system_releases',
    'operating_system_versions',
    'space',
    'topic',
  ];
  protected static defaultReactQueryConfig: UseQueryOptionsWithPrefetch = {
    staleTime: 60000, // 1 minute
  };

  public static getLocalizedContentEntryIdByLanguage = (
    contentEntry: ContentEntryModel,
    languageShortCode: string
  ) =>
    contentEntry.localizedContentEntries.find(
      (lc: LocalizedContentEntryModel) =>
        lc.languageShortCode === languageShortCode
    )?.id;

  public static getLocalizedContentEntryIdByLocale = (
    contentEntry: ContentEntryModel,
    locale: string
  ) =>
    contentEntry.localizedContentEntries.find(
      (lc: LocalizedContentEntryModel) => lc.locale === locale
    )?.id;

  /**
   *
   * @param contentEntryId The content entry to copy
   * @param attributes The attributes and fields to override/change when copying,
   *   any values not provided will use the values from the source content entry
   * @returns An array of copied content entries
   */
  public static copyAsync(
    contentEntryId: number,
    attributes: ContentEntryCopyModel
  ): Promise<ContentEntryModel[]> {
    return new Promise(async (resolve, reject) => {
      const resourcePath = `authoring/content_entries/${contentEntryId}/copy`;
      try {
        const contentEntry = await createData(
          resourcePath,
          keysToSnake(attributes)
        );
        resolve(contentEntry);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Create a ContentEntry and LocalizedContentEntries for each language provided
   *
   * @param contentEntryData Data required for the content entry
   * @param localizedContentEntryProperties Data for the properties of the LCE
   * @param languagesIds The language IDs to create LCEs for
   * @returns ContentEntry - The created content entry
   */
  public static createWithLocalizedContentEntriesAsync(
    contentEntryData: ContentEntryCreateModel,
    localizedContentEntryProperties: Record<any, any>,
    languagesIds: number[]
  ): Promise<ContentEntryModel> {
    return new Promise(async (resolve, reject) => {
      try {
        const contentEntry = await this.createAsync(contentEntryData);
        const { id: contentEntryId } = contentEntry;

        await Promise.all(
          languagesIds.map((languageId) =>
            LocalizedContentEntry.createAsync(
              {
                contentEntryId,
                languageId,
                localeId: undefined,
                properties: localizedContentEntryProperties,
              },
              { contentEntryId }
            )
          )
        );

        // Now that all the localized content entries have been created,
        // refetch the content entry so the cached version has all the LCE's
        // Otherwise the user might get an error on navigation to the new content entry
        // if the data from when it was created is still considered fresh
        await this.getAsync({ id: contentEntryId }, { staleTime: -1 });

        resolve(contentEntry);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Search for ContentEntries by text and attributes
   *
   * @param searchParams The terms to use in the search
   * @returns A promise that resolves to an array of Content Entries
   */
  public static searchAsync(
    searchParams: ContentEntrySearchParams,
    options?: UrlParamOptions
  ): Promise<ContentSearchResult[]> {
    return this.createAsyncWithCache<
      ContentEntrySearchParams,
      ContentSearchResult[]
    >(searchParams, 'authoring/content_entries/search', undefined, options);
  }

  /**
   * returns exported translations in json format
   *
   */
  public static exportTranslationsAsync(
    contentEntryIds: number[],
    sourceLocaleId: number,
    targetLocaleIds: number[]
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const results = await createData(
          'authoring/content_entries/translations/export',
          keysToSnake({ contentEntryIds, sourceLocaleId, targetLocaleIds })
        );
        return resolve(results);
      } catch (error) {
        reject(error);
      }
    });
  }

  public static importTranslationsAsync(formData: FormData): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const successes = await ozmoApiRequest(
          'authoring/content_entries/translations/import',
          formData,
          'PATCH',
          false,
          false
        );
        return resolve(successes);
      } catch (error) {
        reject(error);
      }
    });
  }

  public static bulkAsync(
    operations: BulkOperation[]
  ): Promise<{ job: number }> {
    return new Promise(async (resolve, reject) => {
      try {
        const body = operations.map((op) => JSON.stringify(op)).join('\n');
        const result = await createData(
          'authoring/content_entries',
          body,
          undefined,
          undefined,
          false,
          { 'Content-Type': 'application/x-ndjson' }
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  public static async getHigherOrderAttributes(
    attrs: SelectedAttributes
  ): Promise<SelectedAttributes> {
    const body = keysToSnake(attrs);

    return createData(
      'authoring/content_entries/higher_order_attributes',
      body
    );
  }
}

export default ContentEntry;
