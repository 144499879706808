import { FunctionComponent } from 'react';
import EditOff from '@mui/icons-material/EditOff';

import BaseStaticToast from './base-static-toast';

const LegacyStaticToast: FunctionComponent = () => (
  <BaseStaticToast
    title="This content is read-only"
    message="Attributes remain editable, but you cannot make changes to step text, notes, or images."
    icon={<EditOff />}
  />
);

export default LegacyStaticToast;
