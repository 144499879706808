import { useCallback, useState, useEffect } from 'react';
import keysToSnake from 'services/utils/convert-object-keys-camel-to-snake';
import { useAppToast } from 'contexts/app-toast-context';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import getPlural from 'services/utils/get-plural';
import { SelectChangeEvent } from '@mui/material';
import { isDefined } from 'services/utils/type-guards/generic';

import { downloadFile } from './translation-export-utils';

export const useTranslationExport = (id: number, languageId?: number) => {
  const api = useOzmoApiService();
  const { isLoading, data: collection } = api.Collection.get(
    {
      id,
    },
    undefined,
    undefined,
    { languageId }
  );
  const { getFromLanguage: getLocaleFromLanguage } = api.Locale.getAll();
  const {
    all: languages,
    isLoading: languagesIsLoading,
  } = api.Language.getAll();
  const { name } = collection ?? {};
  const dispatchToast = useAppToast();
  const [targets, setTargets] = useState<number[]>([]);
  const [source, setSource] = useState(languageId);

  // Source is fed from a prop and can change after instantiation if the user
  // selects a new language from the dropdown, so reset if it changes
  useEffect(() => setSource(languageId), [languageId]);

  const onExportRefuse = useCallback(() => {
    setTargets([]);
  }, []);

  const handleChangeSource = (e: SelectChangeEvent<number>) => {
    const {
      target: { value },
    } = e;
    setSource(Number(value));
  };
  const handleSelectTarget = (languageIds: number[]) => {
    setTargets(languageIds);
  };

  const languagesOptions = languages.map(({ id, shortCode, displayName }) => ({
    value: id,
    chipLabel: shortCode,
    label: `${displayName} (${shortCode})`,
  }));

  // If there are 0 targets, just read "Export files for translations",
  // otherwise do the plural for "Export x file(s) for translations"
  const exportButtonText = `Export ${
    targets.length === 0
      ? 'files'
      : `${targets.length} ${getPlural(targets.length, 'file', 'files')}`
  } for translation`;

  const handleExportSubmit = async () => {
    const uniqueContentEntryIds = new Set(
      collection.categories.reduce<number[]>(
        (acc, cur) => [...acc, ...cur.contentEntries.map(({ id }) => id)],
        []
      )
    );

    const sourceLocaleId = getLocaleFromLanguage(source!)?.id;
    const targetLocaleIds = targets.map((t) => getLocaleFromLanguage(t)?.id);

    if (
      !isDefined(sourceLocaleId) ||
      targetLocaleIds.some((t) => t === undefined)
    ) {
      dispatchToast({
        level: 'error',
        message:
          'The source or target languages could not be mapped to locales',
      });
      return;
    }

    try {
      const response = await api.ContentEntry.exportTranslationsAsync(
        Array.from(uniqueContentEntryIds),
        sourceLocaleId,
        targetLocaleIds as number[] // TS can't seem to infer that we've already verified this array is only numbers above
      );

      const snakeyResponse = keysToSnake(response);
      Object.entries(snakeyResponse).forEach(([key, value]) => {
        downloadFile(key, value);
      });
    } catch (error) {
      dispatchToast({
        level: 'error',
        message:
          'An error occured while generating your translated files. Please try your export again.',
      });
    } finally {
      onExportRefuse();
    }
  };

  return {
    handleSelectTarget,
    handleExportSubmit,
    onExportRefuse,
    handleChangeSource,
    languages: languagesOptions,
    targets,
    source,
    exportButtonText,
    isLoading: isLoading || languagesIsLoading,
    name,
  };
};
