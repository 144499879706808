import { BaseEditor, Descendant, Editor, Node, Text } from 'slate';
import { tokenize, languages, TokenStream } from 'prismjs';
import { FormattedText, LinkElement, ParagraphElement } from 'types/slate';
import { isNotNull } from 'services/utils/type-guards/generic';

import { isLinkElement, isPrismToken, MarkOptions } from './types';
import './prism-markdown';

export const HOTKEYS: { [key: string]: MarkOptions } = {
  b: 'bold',
  i: 'italic',
};

export const isUrl = (text: string) => new RegExp(/https?:\/\//).test(text);

export const isMarkActive = (editor: BaseEditor, format: MarkOptions) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMark = (editor: BaseEditor, format: MarkOptions) => {
  if (isMarkActive(editor, format)) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

// Convert a slate text node into corresponding markdown
const formatTextNode = (node: Text) => {
  let formatted = node.text;
  if (node.bold) {
    formatted = `**${formatted}**`;
  }
  if (node.italic) {
    formatted = `_${formatted}_`;
  }

  return formatted;
};

// Parse a slate node and get a string ready for display in the editor
const parseNode = (node: Descendant): string => {
  if (Text.isText(node)) {
    return formatTextNode(node);
  }
  if (isLinkElement(node)) {
    return `[${Node.string(node)}](${node.url})`;
  }

  return node.children.map((n) => parseNode(n)).join('');
};

/**
 * Converts Slate-formatted JSON data to Markdown string ready
 * for the storage in the LocalizedContentVersion's properties
 * @param value Slate-formatted JSON data to convert
 * @returns string representation of the slate data in markdown
 */
export const serialize = (value: Descendant[]) => {
  return (
    value
      // Return the string content of each paragraph in the value's children.
      .map(parseNode)
      // Join them all with line breaks denoting paragraphs.
      .join('\n')
  );
};

/**
 * Recursive method to fully parse and convert to slate-flavored JSON
 * the results of calling Prism.tokenize(markdown: string)
 * @param ts A PrismJS TokenStream
 * @param bold Is the node bold
 * @param italic Is the node italic
 * @param link  Is the node a link
 * @returns An array of Slate-formatted JSON objects (formatted texts and links)
 */
const parseTokenStream = (
  ts: TokenStream,
  bold = false,
  italic = false,
  link = false
): (LinkElement | FormattedText | null)[] => {
  if (Array.isArray(ts)) {
    if (link && typeof ts[0] === 'string') {
      const [, text, url] = ts[0].match(/\[(.*)\]\((.*)\)/) ?? [];
      return [{ type: 'link', url, children: [{ text }] }];
    }
    return ts.map((t) => parseTokenStream(t, bold, italic)).flat();
  }
  if (isPrismToken(ts)) {
    // Prism creates "punctuation" nodes which we don't need to keep,
    // for example, "**bold text**" becomes [{ type: "punctuation", content: "**" }, { content: "bold text" },...etc]
    // So return null here and we'll filter them out at the end
    if (ts.type === 'punctuation') {
      return [null];
    }
    return parseTokenStream(
      ts.content,
      ts.type === 'bold' || bold,
      ts.type === 'italic' || italic
    );
  }
  return [{ text: ts, bold, italic }];
};

/**
 * Converts Markdown-formatted string data from the LocalizedContentVersion's
 * properties into Slate-formatted JSON data ready for the editor
 * @param value Markdown-formatted string to convert
 * @returns A representation of the data in slate-flavored JSON
 */
export const deserialize = (value: string): ParagraphElement[] =>
  value.split('\n').map((text) => {
    // We use the PrismJS library to help convert Markdown-flavored strings
    // into _some kind_ of JSON, then fiddle with it to properly match the
    // JSON format that SlateJS expects to ingest.
    // This is based in part on the Markdown Preview example in the Slate docs:
    // https://www.slatejs.org/examples/markdown-preview
    const tokens = tokenize(text, languages.markdown);
    const children = tokens
      .map((t) => {
        if (isPrismToken(t)) {
          const bold = t.type === 'bold';
          const italic = t.type === 'italic';
          const link = t.type === 'url';
          return parseTokenStream(t.content, bold, italic, link);
        }

        return [{ text: t }];
      })
      .flat()
      .filter(isNotNull); // filter out all the nulls from punctuation

    return {
      type: 'paragraph',
      children,
    };
  });

/**
 * Absolutely impenatrable regex to find and group Ozmo-specific formatting.
 * Finds groups that have these three parts (<*)(something else)(*>)
 *
 * (<\*\s?) - Find an opening "<*" with or without trailing space
 *
 * (((?!<\*\s?|\s?\*>).)*) - Find anything that doesn't contain a "<*" or "*>",
 *   plus or minus leading/trailing space, so we don't capture incorrectly
 *   if the string contains multiple bold tags
 *
 * (\s?\*>) - Find a closing "*>" with or without leading space
 */
const OZMO_FORMATTING_REGEX = /(<\*\s?)(((?!<\*\s?|\s?\*>).)*)(\s?\*>)/g;

/**
 * Converts text containing Ozmo's weirdo google sheets step text formatting for
 * bold text into standard markdown.
 * @param value A text string possibly containg Ozmo's weird <*bold text*> formatting
 * @returns A text string containing markdown-formatted text
 */
export const convertOzmoFormatToHTML = (ozmoFormattedText: string) =>
  ozmoFormattedText.replace(OZMO_FORMATTING_REGEX, '<b>$2</b>');

/**
 * Does a piece of string text contain any Ozmo-formatted tags such as <*bold*>
 * @param value String text to test if it contains any ozmo-formatted text
 * @returns True if it contains ozmo-formatted text, otherwise false
 */
export const hasOzmoFormattedText = (value: string) =>
  (value.match(OZMO_FORMATTING_REGEX)?.length ?? -1) > 0;
