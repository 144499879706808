import { FunctionComponent, useMemo } from 'react';
import { Skeleton, styled } from '@mui/material';
import StaggeredRenderedList from 'components/staggered-rendered-list';
import { isTopicGroup } from 'services/utils/type-guards/collection';

import { AttributeIds, useCurrentCategory } from '../category';
import CategoryHeader from '../category/category-header';
import useAnswerFiltering from '../category/use-answer-filter/use-answer-filter';

import ReferenceCard from './reference';
import { extractTopicGroups } from './utils';
import TopicGroup from './reference/topic-group';
import NoAnswers from './no-answers';

const StyledCategoryHeader = styled(CategoryHeader)`
  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

type Props = {
  attributeIds: AttributeIds;
  collectionId: number;
  collectionName: string;
  languageId: number;
  onInfoClick: (id: number) => void;
  onSelectReference: (id: number, catId: number) => void;
};

const AllAnswers: FunctionComponent<Props> = ({
  attributeIds,
  collectionId,
  collectionName,
  languageId,
  onInfoClick,
  onSelectReference,
}) => {
  const { isLoading, categories } = useCurrentCategory();
  const { filterCount } = useAnswerFiltering();

  const consolidatedList = useMemo(
    () =>
      categories.reduce<JSX.Element[]>((acc, cur) => {
        if (cur.contentEntries.length === 0 && filterCount > 0) return acc; // skip those categories with no visible references
        return [
          ...acc,
          <StyledCategoryHeader
            key={cur.id}
            attributeIds={attributeIds}
            count={cur.contentEntries.length}
            collectionId={collectionId}
            collectionName={collectionName}
            existingReferences={cur.contentEntries.map(({ id }) => id)}
            id={cur.id}
            languageId={languageId}
            name={cur.name}
            marginBottom={0}
          />,
          ...extractTopicGroups(cur.contentEntries).map((c) =>
            isTopicGroup(c) ? (
              <TopicGroup
                key={`tg-${c[0].topicSlug}-${cur.id}`}
                categoryId={cur.id}
                collectionId={collectionId!}
                references={c}
                onSelectChange={onSelectReference}
                onInfoClick={onInfoClick}
              />
            ) : (
              <ReferenceCard
                onInfoClick={onInfoClick}
                onSelectChange={onSelectReference}
                collectionId={collectionId!}
                categoryId={cur.id}
                key={`${cur.id}-${c.id}`}
                {...c}
              />
            )
          ),
        ];
      }, []),
    [
      attributeIds,
      categories,
      filterCount,
      collectionId,
      collectionName,
      languageId,
      onInfoClick,
      onSelectReference,
    ]
  );

  const totalReferences = useMemo(
    () => categories.flatMap((cat) => cat.contentEntries).length,
    [categories]
  );

  if (totalReferences === 0 && filterCount > 0) {
    return <NoAnswers />;
  }

  if (isLoading || !collectionId) {
    return <Skeleton variant="rectangular" height="100px" width="100%" />;
  }

  return (
    <StaggeredRenderedList display="flex" flexDirection="column" gap={1}>
      {consolidatedList}
    </StaggeredRenderedList>
  );
};

export default AllAnswers;
