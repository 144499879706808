import { FunctionComponent } from 'react';
import { FormatBold, FormatItalic } from '@mui/icons-material';
import { Editable, Slate } from 'slate-react';
import { Box, ClickAwayListener, styled } from '@mui/material';

import { FormatButton, LinkButton, Toolbar } from './components';
import { useRichTextEditor } from './use-rich-text-editor';

const StyledEditable = styled(Editable)`
  border: 1px solid var(--color-neutral-four);
  background-color: var(--color-neutral-one);
  border-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding: ${({ theme }) => theme.spacing(1)};
  p:first-of-type {
    margin-block-start: 0;
  }
  p:last-of-type {
    margin-block-end: 0;
  }
`;

type Props = {
  initialValue: string;
  onSave: (text: string) => void;
  autoFocus?: boolean;
};

const RichTextEditor: FunctionComponent<Props> = ({
  initialValue,
  onSave,
  autoFocus = true,
}) => {
  const {
    editor,
    value,
    renderElement,
    renderLeaf,
    handleEditorKeydown,
    handleClickAway,
  } = useRichTextEditor(initialValue, onSave);

  return (
    <Slate editor={editor} value={value}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box flex="auto">
          <Toolbar>
            <FormatButton format="bold" tooltip="Bold (ctrl+b)">
              <FormatBold />
            </FormatButton>
            <FormatButton format="italic" tooltip="Italic (ctrl+i)">
              <FormatItalic />
            </FormatButton>
            <LinkButton />
          </Toolbar>
          <StyledEditable
            autoFocus={autoFocus}
            data-testid="rich-text-editor:slate-editor"
            renderLeaf={renderLeaf}
            renderElement={renderElement}
            onKeyDown={handleEditorKeydown}
          />
        </Box>
      </ClickAwayListener>
    </Slate>
  );
};

export default RichTextEditor;
