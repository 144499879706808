import {
  useState,
  useCallback,
  MouseEvent,
  FC,
  ComponentClass,
  ReactElement,
} from 'react';
import { Button, Menu, ButtonProps, MenuProps } from '@mui/material';

type Props = Omit<ButtonProps, 'onClick'> & {
  as?: ComponentClass;
  label: string;
  keepMounted?: MenuProps['keepMounted'];
  children: ReactElement[];
};

const MenuButton: FC<Props> = ({
  as = Button,
  label,
  children,
  keepMounted = true,
  ...buttonProps
}) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const handleButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      setAnchorElement(e.currentTarget);
    },
    [setAnchorElement]
  );

  const handleMenuClose = useCallback(() => {
    setAnchorElement(null);
  }, [setAnchorElement]);

  const RenderButton = as;

  return (
    <>
      <RenderButton {...buttonProps} onClick={handleButtonClick}>
        {label}
      </RenderButton>
      <Menu
        keepMounted={keepMounted}
        id="add-new:menu"
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
      >
        {children}
      </Menu>
    </>
  );
};

export default MenuButton;
